<template>
  <div class="p-3 d-lg-none box-my-account">
    <VueSlickCarousel
      class="d-lg-none"
      v-bind="slickSettings"
      v-if="toasts.length"
    >
      <div v-for="(toast, i) in toasts" :key="i" class="border bg-white rounded d-flex align-items-center">
        <router-link :to="`/product/${toast.slug}`">
          <b-img-lazy width="96" height="96"
            class="rounded"
            :class="{'default-image p-3' : toast.image_url.indexOf('default-image') >= 0}"
            :src="`${toast.image_url}?w=128`"
            :title="toast.product_name"
            :alt="toast.product_name"
            @error.native="replaceByDefaultImage(toast)"
          />
        </router-link>
        <div class="pl-2">
          <small v-if="toast.paid_at">Someone purchased</small>
          <router-link class="custom-toast-product" :to="`/product/${toast.slug}`">
            {{toast.product_name}}
          </router-link>
          <small class="d-block text-grey">
            <template v-if="toast.paid_at">
              <!-- <fawesome-pro variant="far" icon="shopping-cart" /> Telah dibeli pada<br/> -->
              {{formatDate(toast.paid_at)}}
            </template>
            <template v-else>
              <!-- Already seen {{(number => `${number} time${number == 1 ? '' : 's'}`)(toast.total_view)}} -->
              is trending now
            </template>
          </small>
        </div>
      </div>
    </VueSlickCarousel>
    <!-- <div v-if="toasts.length" class="border bg-white rounded d-flex align-items-center">
    </div> -->
    <b-toast id="custom-toast" toaster="b-toaster-bottom-left custom-toast" toast-class="rounded d-none d-lg-block" body-class="p-0" solid no-close-button @hidden="selectNext()">
      <button type="button" class="btn close-trigger" @click="$bvToast.hide('custom-toast')">&times;</button>
      <div v-if="toasts.length" class="d-flex align-items-center">
        <router-link :to="`/product/${toasts[index].slug}`">
          <b-img-lazy width="96" height="96"
            class="rounded"
            :class="{'default-image p-3' : toasts[index].image_url.indexOf('default-image') >= 0}"
            :src="`${toasts[index].image_url}?w=128`"
            :title="toasts[index].product_name"
            :alt="toasts[index].product_name"
            @error.native="replaceByDefaultImage(toasts[index])"
          />
        </router-link>
        <div class="pl-2">
          <small v-if="toasts[index].paid_at">Someone purchased</small>
          <router-link class="custom-toast-product" :to="`/product/${toasts[index].slug}`">
            {{toasts[index].product_name}}
          </router-link>
          <small class="d-block text-grey">
            <template v-if="toasts[index].paid_at">
              <!-- <fawesome-pro variant="far" icon="shopping-cart" /> Telah dibeli pada<br/> -->
              {{formatDate(toasts[index].paid_at)}}
            </template>
            <template v-else>
              <!-- Already seen {{(number => `${number} time${number == 1 ? '' : 's'}`)(toasts[index].total_view)}} -->
              is trending now
            </template>
          </small>
        </div>
      </div>
    </b-toast>
  </div>
</template>
<script>
import { toaster } from '@/_helpers';
import VueSlickCarousel from 'vue-slick-carousel'
import moment from 'moment';

export default {
  name: "CustomToast",
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      toasts: [],
      index: 0,
      slickSettings: {
        centerMode: true,
        centerPadding: "0px",
        slidesToShow: 1,
        arrows: false,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 5000,
      },
    }
  },
  mounted() {
    this.getLatestToast()
  },
  methods: {
    async getLatestToast() {
      try {
        const res = await this.$api.product.getLatestToast()
        if (res.status === 200) {
          this.toasts = res.data.data
          setTimeout(() => this.$bvToast.show('custom-toast'), 10000)
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    selectNext() {
      this.index++
      if (this.index == this.toasts.length) this.index = 0
      setTimeout(() => this.$bvToast.show('custom-toast'), 10000)
    },
    formatDate(value, format = 'D MMMM YYYY, HH:mm') {
      const dateThen = new Date(value)
      const dateNow = new Date().getDate()
      const dateString = ((value) => value == 0 ? 'Today' : value == 1 ? 'Yesterday' : '')(dateNow - dateThen.getDate())
      return `${dateString ? dateString + ', ' : ''}${(value ? moment(String(new Date(value))).format(dateString ? 'HH:mm' : format).toString() : value)}`
    },
    replaceByDefaultImage(item) {
      item.image_url = require('@/assets/img/default-image.png')
    }
  }
}
</script>
<style scoped>
a img {
  object-fit: contain;
}
.custom-toast-product {
  color: #000;
  font-family: 'Roboto Bold';
  font-size: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.close-trigger {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 10px;
  font-size: 2em;
  font-weight: 400;
  cursor: pointer;
}
</style>
